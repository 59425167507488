@import '~antd/dist/antd.css'; // 引入官方提供的 scss 样式入口文件
@import './reactResolve.scss';
* {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background:transparent;
    }
    &::-webkit-scrollbar-thumb {
      height: 90px;
      background: rgb(94, 102, 115);
      border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    line-height: 1.5;
}
h1,h2,h3,h4,h5,h6 { font-size:100%}
/* Removes list-style from lists */
ol,ul { list-style:none }
/* Normalizes font-size and font-weight to normal */
address,caption,cite,code,dfn,em,strong,th,var { font-size:normal; font-weight:normal }

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
::-webkit-input-placeholder{
  color: #878AA0;
}
html {
    body {
        font-size: 12px;
        font-family: Arial, PingFangSC-Regular, 'Microsoft YaHei', sans-serif !important; // PingFangSC-Medium, PingFang SC
        background-color: #F5F5F5;
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-tap-highlight-color: transparent;
        // &::-webkit-scrollbar {
        //     display: none;
        // }
        #root{
          display: flex;
          flex-direction: column;
          min-height: 100%;

        }
        .page{
          flex: 1;
          display: flex;
          flex-direction: column;
        }
    }
  }




.mt10{margin-top:10px;}
.mt26{margin-top:26px;}
.global_loading{
  position: absolute;
  top: 50%;
  left: 50%;
}
