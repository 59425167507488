// .avatar-uploader{
//   background: url(images/img_comingsoon.png) no-repeat center;
// }
//修改对话框样式
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  padding: 0 16px;
}
.ant-modal-content{
  // top: 175px;
  // background-color: #242424;
  border-radius: 12px 12px 12px 12px;
  .ant-modal-header{
    text-align: center;
    // background-color:#242424;
    border: none;
    border-radius: 12px 12px 12px 12px;
    .ant-modal-title{

      margin-top: 11px;
    }
  }
  .ant-modal-body{
    border-radius: 0 0 12px 12px;
  }
  .ant-modal-footer{
    border-color: #3F4563;
  }
}

.ant-select-selection-item{
  height: 48px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  height: 48px;
  border-radius: 4px;
  border: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none;
  box-shadow: none;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #F2F9FF;
}