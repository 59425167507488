.cardBox{
    width: 100%;
    background-color: #0F1642;
    color: white;
    .empty{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        img{
            width: 106px;
        }
        p{
            margin-top: 23px;
        }
    }
}