.cross-modal.ant-modal{
    .ant-modal-content{
        border-radius: 12px;
    }
    
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
        border-radius: 18px 0 0 18px;
        background-color:  #F7F7F7;
        border-color: #F7F7F7;
        box-shadow: none;
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child{
        left: 1px;
    }
    .ant-btn{
        border-right-color: none;
    }
    .ant-modal-header{
        text-align: center;
        border-bottom: none;
        border-radius: 12px 12px 0 0;
    }
    .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus{
        border-color:#F7F7F7;
    }
    .ant-input:focus, .ant-input-focused{
        box-shadow: none;
    }
    .ant-modal-body{
        
        .ant-input-group-wrapper{
            width: 100%;
            .ant-input-wrapper{
                display: flex;
                flex-direction: row-reverse;
                input{
                    border-radius: 0 18px 18px 0;
                    background-color: #F7F7F7;
                    margin-left: 30px;
                    border-color: #F7F7F7;
                }
                .ant-input-group-addon{
                    border-radius: 18px 0 0 18px;
                }
                
            }
            .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
                background-color: #F7F7F7;
            }
        }
    }
    .cross_modal_content{
        height: 384px;
        overflow-y: auto;
        // margin-bottom: 29px;
        margin-top: 24px;
        .listNone{
            color: #788AAE;
            font-size: 12px;
            margin-top: 70px;
            text-align: center;
        }
        .cross_modal_list{
            display: flex;
            align-items: center;
            padding: 10px 0;
            img{
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 12px;
            }
            div{
                display: flex;
                flex-direction: column;
            }
            span:nth-of-type(1){
                font-size: 14px;
                line-height: 14px;
                font-weight: bold;
            }
            span:nth-of-type(2){
                font-size: 12px;
                line-height: 18px;
                color: rgba(0, 0, 0,.4);
            }
        }
    }
}