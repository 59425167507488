.tabBox{
    height: 64px;
    background-color:#0F1642;
    border-radius: 4px;
    display: flex;
    margin-left: 40px;
    .row{
        padding: 0 20px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        color: #6F738D;
        span{
            height: 64px;
            line-height: 64px;
            display:block;
            min-width: 100px;
            
        }
    }
    .on{
        background-color: #0F1642;
        color: #FFFFFF;
        border-bottom: 3px solid #FFFFFF;
        span{
            position: relative;
        }   
    }
}
