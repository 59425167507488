.inputChange{
    .inputType{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
        border: 1px solid #505CA7;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
        input{
            width: 100%;
            padding-left: 14px;
            height: 100%;
            border: none;
            background: #1B214B;
            background: transparent;
        }
        .rightName{
            width: 10%;
            text-align: center;
        }
    }
    .inputType.small{
        height:32px;
    }
    .inputTypex.middle{
        height:42px;
    }
    .inputType.large{
        height:48px;
    }
    .inputType.smallWidth{
        width: 100px;
    }
}

