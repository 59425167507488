
.buttonBox{display:flex;text-align:center;
    .box{
        padding:0 21px;
        height: 42px;
        border-radius: 4px;
        background:#FBD528;
        display: flex;
        cursor: pointer;
        transition: all 0.5s;
        user-select: none;
        text-align: center;
        justify-content: space-between;
        .texts{
            line-height: 100%;
            font-size: 16px;
            color: #242424;
            display:flex;
            justify-content: center;
            align-items: center;

        }
    }
    .box:hover{
        // background: #7c72ff;
        box-shadow: -1px 1px 8px 0px rgba(126, 114, 242, 0.58);

    }

    // 尺寸
    .box.small{
        height:32px;
        .texts{
            font-size: 12px;
        }

    }
    .box.middleWidth{
        width: 100px;
    }
    .box.middle{
        height:42px;
    }
    .box.large{
        height:48px;
    }
    .box.normal{
        border: 1px solid #505CA7;
        color: #FFFFFF;
        background: #0F1642;
    }
    // 渐变背景
    .box.gradient{
        background: linear-gradient(225deg, #9E94FF 0%, #7E72F2 100%);
    }

    // 圆角
    .box.circle{
        border-radius: 100vh;
    }

    // 撑满
    .box.fill{
        width:100%;
        box-sizing: border-box;
    }
    //圆
    .box.round{
        border-radius: 100%;
        justify-content: center;
    }
    // 禁用
    .box.disable{
        background: rgba(88, 93, 123,.08);
        border: 1px solid rgba(255, 255, 255,.2);
        pointer-events: none;
        .texts{
            span{
                color: rgba(255, 255, 255,.25);
            }
        }

    }
}
