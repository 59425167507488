@media (min-width:600px){
    .have_banner{
        display: flex;
        justify-content: space-between;
        flex: 1;
    }
    .mobile_banner{
        display: none;
    }
    .mobile_logo{
        display: none;
    }
    .banner{
        width: 50%;
        
        overflow: hidden;
        .logo_corss{
            position: absolute;
            bottom: 64px;
            right: 64px;
            width: 220px;
            img{
                width: 100%;
            }
        }
        .run{
            position: absolute;
            top: 20px;
            right: 168px;
            line-height: 42px;
            color: #FFFFFF;
            font-size: 14px;
        }
    }
    .HeaderLanuage{

        position: absolute;
        top: 20px;
        right: 48px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .ant-select-arrow{
            color: #f5f5f5;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            width: 90px;
            height: 42px;
            display: flex;
            align-items: center;
            background-color: transparent;
        }
        .ant-select-selection-item{
            height: 30px;
            color: #F5F5F5;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            padding: 0 11px;
          }
    }
    .newHome{
        width: 50%;
        padding: 0 78px;
    }
    @media (max-width:1300px){
        .newHome{
            width: 50%;
            padding: 0 20px;
        }
    }
    .homePage{
        width: 100%;
        display: flex;
        justify-content: center;
        .crossChain {
            width: 100%;
            .sel_fat{
                display: flex;
                justify-content: flex-end;
            }
            .select_box{
                width: 222px;
                height: 46px;
                line-height: 46px;
                border-radius: 23px;
                border: 1px solid #D2D5E1;
                display: flex;
                align-items: center;
                background-color: #F5F5F5;
                padding: 4px 6px;
                margin: 18px 0 48px;
                .select_one,.select_two{
                    width: 50%;
                    text-align: center;
                    font-size: 14px;
                    cursor: pointer;
                }
                .select_one{
                    height: 38px;
                    background-color: #FFFFFF;
                    border-radius: 19px;
                    line-height: 38px;
                }
                .select_two{
                    color: rgba(0, 0, 0,.25);
                }
            }
            .check{
                margin-right: 10px;
                margin-top: 20px;
            }
            .banner_img{
                display: flex;
                justify-content: center;
                img{
                    width: 280px;
                }
            }
            .tokenType{
                p{
                    font-size: 14px;
                    margin-bottom: 11px;
                }
                .token_sel{
                    width: 100%;
                    height: 48px;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                    padding: 10px 16px;
                    font-size: 16px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .token_sel_div{
                        display: flex;
                        align-items: center;
                    }
                    img{
                        width: 28px;
                        margin-right: 12px;
                    }
                }
            }
            .ant-select-selection-item{
                height: 48px;
        
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector{
                height: 48px;
                border-radius: 4px;
                border: none;
            }
            .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
                border: none;
                box-shadow: none;
            }
            .change_chain{
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
                .chain_img{
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    img{
                        width: 40px;
                        height: 42px;
                        margin-top: 38px;
                    }
                }
                .chain_first,.chain_second{
                    // min-width: 248px;
                    flex: 5;
                }
                .sel_con{
                    display: flex;
                    padding: 7px 0px 7px 0px;
                    height: 48px;
                    box-sizing: border-box;
                    align-items: center;
                    img{
                        width: 28px;
                        height: 28px;
                    }
                    div{
                        display: flex;
                        flex-direction: column;
                        height: 30px;
                        margin-left: 12px;
                        justify-content: space-between;
                        span:nth-of-type(1){
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: bold;
                        }
                        span:nth-of-type(2){
                            font-size: 12px;
                            line-height: 12px;
                            color: rgba(0, 0, 0,.4);
                        }
                    }
                }
                .change_chain_text{
                    font-size: 14px;
                    display: inline-block;
                    margin-bottom: 11px;
                }
            }
            .Addr_chain{
                width: 100%;
                padding: 10px 12px;
                background-color: #E8ECF3;
                border-radius: 4px;
                margin-top: 8px;
                .Addr_chain-bottom{
                    margin-top: 12px;
                }
                h3{
                    color: rgba(0, 0, 0,.5);
                    font-size: 14px;
                    margin-bottom: 0;
                }
                .addr_a{
                    display: flex;
                    align-items: center;
                    span{
                        color: #108EE9;
                        font-size: 14px;
                    }
                    .img{
                        width: 24px;
                        height: 24px;
                    }
                }
                img{
                    width: 24px;
                    margin-left: 12px;
                }
            }
            .coinTransfer{display:flex; justify-content: space-between; align-items: flex-start;text-align:center; width:92%;
                .icon{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-width:150px;
                    img{width:80px;
                        height:80px;
                        border: 2px solid #000000;
                        border-radius:100%;
                        overflow:hidden;}
                    .title{display:block;
                        width: 80px;
                        line-height: 36px;
                        background: rgba(73, 164, 203, 0.2);
                        text-align:center;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        span{
                            display:block;
                            font-size: 18px;
                            font-weight: bold;
                            text-align: center;
                            color: #000000;}
                    }
                }
                .iconChange{width:48px; height:48px;
                    span{display:block;}
                    // span.rotate{transform: rotateY(180deg);}
                    img{width:100%; margin-top:16px; cursor: pointer;}
                }
            }
            .run{flex-direction: row-reverse;}
            .form{padding-top:16px;
                input{
                    width:100%;
                    background: #ffffff;
                    border-radius: 4px;
                    border: none;
                    outline: none;
                    height: 32px;
                    margin: 4px 0;}
                button{width:100%;}
                .btn-yellow{
                    background-color: #0081FF;
                    color: #FFFFFF;
                    height: 42px;
                    font-size: 14px;
                    border-radius: 2px;
                    margin-bottom: 3px;
                }
                .btn-yellow.disable{
                    background-color: #99CDFF;
                }
                .rowText{
                    color: rgba(0, 0, 0,.5  );
                }
                .ant-form-item{margin:0;}
                .ant-row{padding-bottom:4px;}
        
                .label{ line-height: 36px; font-weight:bold; color:#000; margin-bottom: 0;   }
                .upperBtn{
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 0 11px;
                    line-height: 40px;
                    font-weight: 500;
                    color: #0081ff;
                    font-size: 14px;
                    cursor: pointer;
                }
                .amount{padding-bottom:0;
                    display: flex;
                    .text{
                        font-size: 14px;
                        // font-weight: bold;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    }
                    .label{line-height: 24px;font-weight: 500;color: rgba(0, 0, 0, 0.5);font-size: 14px;}
                }
                .tradingFee{padding:0px 0 40px;
                    display: flex;
                    .text{
                        font-size: 14px;
                        // font-weight: bold;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    }
                    .label{line-height: 24px;font-weight: 500;color: rgba(0, 0, 0, 0.5);font-size: 14px;}
                }
                .default{
                    padding-left: 10px;
                    margin-top: 20px;
                }
            }
        }

        
    }
    .ant-select-dropdown.list{
        border: none;
        border-radius: 4px;
        .sel_con{
            display: flex;
            padding: 7px 0px 7px 0px;
            height: 48px;
            box-sizing: border-box;
            // align-items: center;
            img{
                width: 28px;
                height: 28px;
            }
            div{
                display: flex;
                flex-direction: column;
                height: 28px;
                margin-left: 12px;
                span:nth-of-type(1){
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: bold;
                }
                span:nth-of-type(2){
                    font-size: 12px;
                    line-height: 17px;
                    color: rgba(0, 0, 0,.4);
                }
            }
        }
    }
    .tipTitle{
        max-width: 1200px;
        margin: 30px auto;
        border-top: 1px solid#BCC1CD;
        background-color:#EEEEEE;
        box-sizing: border-box;
        padding: 10px;
    }
}

@media (max-width:599.99px){
    .banner{
        display: none;
    }
    .mobile_logo{
        margin: 16px auto;
        display: flex;
        justify-content: center;
        img{width: 188px;}
    }
    .mobile_banner{
        position: relative;

        
        .box.have{
            display: none;
        }
        .box{
            position: absolute;
            top: 65px;
            width: 100%;
            background-color: #FFFFFF;
            transition: all 1s;
            padding: 16px 0;
        }
        .box_list{
            width: 100%;
            height: 42px;
            line-height: 42px;
            text-align: center;
            font-size: 14px;
            a{
                color: #000000;
            }
        }
        .box_list:hover{
            color: #0081FF;
        }
        .box_list:hover a{
            color: #0081FF;
        }
    }
    .mobile_banner_top{
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
        height: 65px;
        align-items: center;
        padding: 0 18px;
        margin-bottom: 20px;
        box-shadow: 0px 2px 4px 0px rgba(188, 193, 205, 0.1);
        position: relative;
        z-index: 1;
        img{
            width: 24px;
        }
        
        .select_box{
            width: 222px;
            height: 46px;
            line-height: 46px;
            border-radius: 23px;
            border: 1px solid #D2D5E1;
            display: flex;
            align-items: center;
            background-color: #F5F5F5;
            padding: 4px 6px;
            .select_one,.select_two{
                width: 50%;
                text-align: center;
                font-size: 14px;
            }
            .select_one{
                height: 38px;
                background-color: #FFFFFF;
                border-radius: 19px;
                line-height: 38px;
            }
            .select_two{
                color: rgba(0, 0, 0,.25);
                border-radius: 19px;
                line-height: 38px;
                height: 38px;
            }
        }
    }
    .HeaderLanuage{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 12px;
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            width: 90px;
            height: 42px;
            display: flex;
            align-items: center;
            background-color: transparent;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            padding: 0;
        }
        .ant-select-selection-item{
            height: 30px;
            color: #636E95;
        }
    }
    .ant-select-dropdown.list{
        border: none;
        border-radius: 4px;
        .sel_con{
            display: flex;
            padding: 7px 0px 7px 0px;
            height: 48px;
            box-sizing: border-box;
            // align-items: center;
            img{
                width: 28px;
                height: 28px;
            }
            div{
                display: flex;
                flex-direction: column;
                height: 28px;
                margin-left: 12px;
                span:nth-of-type(1){
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: bold;
                }
                span:nth-of-type(2){
                    font-size: 12px;
                    line-height: 17px;
                    color: rgba(0, 0, 0,.4);
                }
            }
        }
    }
   .homePage{
       width: 100%;
       display: flex;
       .container{
           width: 100%;
       }
       .banner_img{
           padding: 24px 28px;
           img{
            width: 100%;
           }
           
       }
       .sel_fat{
           display: none;
       }
       .mobile_box{
           background-color: #F8F9FA;
           border-radius: 4px;
           margin: 0 auto;
           width: 90%;
           padding: 20px 12px;
           .tokenType{
               margin-bottom: 26px;
               p{
                   font-weight: 500;
               }
               .token_sel{
                   display: flex;
                   justify-content: space-between;
                   align-items: center;
                   background-color: #FFFFFF;
                   padding: 10px 16px;
                   .token_sel_div{
                       img{
                           width: 28px;
                           height: 28px;
                       }
                   }
               }
           }
           .change_chain{
               display: flex;
               flex-direction: column;
               align-items: center;
               .chain_img{
                   margin-top: 9px;
                   img{
                       width: 28px;
                       height: 28px;
                       transform: rotate(90deg);
                   }
               }
               .chain_second,.chain_first{
                   width: 100%;
               }
               .sel_con{
                display: flex;
                padding: 7px 0px 7px 0px;
                height: 48px;
                box-sizing: border-box;
                // align-items: center;
                img{
                    width: 28px;
                    height: 28px;
                }
                div{
                    display: flex;
                    flex-direction: column;
                    height: 28px;
                    margin-left: 12px;
                    span:nth-of-type(1){
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: bold;
                    }
                    span:nth-of-type(2){
                        font-size: 12px;
                        line-height: 26px;
                        color: rgba(0, 0, 0,.4);
                    }
                }
            }
            .change_chain_text{
                font-size: 14px;
                display: inline-block;
                margin-bottom: 11px;
            }
           }
       }
       .Addr_chain{
           display: none;
       }
       .form{
           width: 90%;
           margin: 0 auto;
           .form_top{
                width: 100%;
                background-color:#F8F9FA;
                //margin-top: 8px;
                border-radius: 4px;
                margin: 8px auto 48px;
                padding: 20px 12px;
           }
           input{
            width:100%;
            background: #ffffff;
            border-radius: 4px;
            border: none;
            outline: none;
            height: 32px;
            margin: 4px 0;}
            button{width:100%;}
            .btn-yellow{
                background-color: #0081FF;
                color: #FFFFFF;
                height: 42px;
                font-size: 14px;
                border-radius: 2px;
                margin-bottom: 3px;
            }
            .btn-yellow.disable{
                background-color: #99CDFF;
            }
            .rowText{
                color: rgba(0, 0, 0,.5  );
            }
            .ant-form-item{margin:0;}
            .ant-row{padding-bottom:4px;}

            .label{ font-weight:bold; color:#000;  margin-bottom: 0;  }
            .upperBtn{
                position: absolute;
                right: 0;
                top: 4px;
                margin: 0 11px;
                line-height: 32px;
                font-weight: 500;
                color: #0081ff;
                font-size: 14px;
                cursor: pointer;
                display: inline-block;
                background-color: #FFFFFF;

            }
            .amount{padding-bottom:0;
                display: flex;
                .text{
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 24px;
                }
                .label{line-height: 24px;font-weight: 500;color: rgba(0, 0, 0, 0.5);font-size: 14px;}
            }
            .tradingFee{
                display: flex;
                .text{
                    font-size: 14px;
                    // font-weight: bold;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 24px;
                }
                .label{line-height: 24px;font-weight: 500;color: rgba(0, 0, 0, 0.5);font-size: 14px;}
            }
       }
       .images,.images_right{
           display: none;
       }
   }
   .tipTitle{
        width: 90%;
        margin: 19px auto;
        border-top: 1px solid#000000;
        background-color: #EEEEEE;
        box-sizing: border-box;
        padding: 10px;
    }
    .font14 {
        font-size: 14px;
    }
}

