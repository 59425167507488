.walletSelect{
    .ant-modal-body{
        background-color: #F5F5F5;
    }
    .wallet-connect{
        .row{
            width: 100%;
            padding: 0 20px;
            height: 60px;
            align-items: center;
            font-size: 18px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            background-color:#E9EEF3;
            border-radius: 4px;
            border: 1px solid #BCC1CD;
            margin-bottom: 8px;
            cursor: pointer;
            user-select: none;
            img{
                width: 40px;
            }
        }
        .row:hover{
            background-color: #0081FF;
            color: #FFFFFF;
        }
    }
}
.walletSelects{
    .ant-modal-header{
        display: none;
    }
    .ant-modal-content{
        background-color:rgba(0, 0, 0,.75);
        font-size: 15px;
        border-radius: 8px;
    }
    .modal_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            margin-bottom: 7px;
        }
        p{
            margin-bottom: 0;
        }
    }
    .ant-modal-body{padding: 35px 16px;}
}